<template>
  <el-dialog
    append-to-body
    width="500px"
    title="新增工资表"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop='company_ids' label='用工单位：'>
            <el-select v-model="form.data.company_ids" multiple filterable placeholder="请选择" value-key="id" style="width:100%">
              <el-option
                v-for="item in companyOpt"
                :key="item.id"
                :label="item.name"
                :value="item">
              </el-option>
            </el-select>
            <div
              class="company_more"
              v-show="this.form.data.company_ids.length > 0"
              @click="showCompanyList">
              已选{{ form.data.company_ids.length }}家企业 >>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop='date' label='工资月份：'>
            <el-date-picker
              v-model="form.data.date"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              format="yyyy 年 MM"
              value-format="yyyy-MM"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>

    <el-dialog
      width="30%"
      title=""
      :visible.sync="innerVisible"
      append-to-body>
      <div class="company-list">
        <p v-for="(item,index) in this.form.data.company_ids" :key="index">{{ index+1 }}、{{item.name}}</p>
      </div>
    </el-dialog>

  </el-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'SettlementAdd',
    computed: {
      ...mapGetters({
        companyOpt: 'companyList', // 获取企业列表
      })
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
        form: {
          loading: false,
          data: {
            company_ids: [],
            start_date: '',
            end_date: '',
          },
          rules: {
            company_ids: [{ required: true, message:'请选择用工单位', trigger: 'change' }],
            date: [{ required: true, message:'请选择工资月份', trigger: 'change'}],
          }
        },
        innerVisible: false,
      }
    },
    methods: {
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let arr = [];
            if(this.form.data.company_ids) {
              this.form.data.company_ids.forEach(v => {
                arr.push(v.id)
              })
            }
            let _params = {
              company_ids: arr.join(","),
              start_date: this.form.data.date[0],
              end_date: this.form.data.date[1],
            }
            this.form.loading = true;
            this.$http.post('/admin/finance/batchGenerate',_params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
                this.$message.success('操作成功！')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      showCompanyList() {
        this.innerVisible = true;
      }
    }
  }
</script>

<style scoped>
.company_more {
  cursor: pointer;
  color: #606266;
  margin-top: 5px;
  display: inline-block;
}
.company_more:hover {
  color: #409EFF;
}
.company-list {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
