<template>
  <el-dialog
    append-to-body
    width="900px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">工资月份</td>
            <td>{{ detailInfo.year}}年{{detailInfo.month}}月</td>
            <td colspan="4" class="des" style="width:120px;">用工单位</td>
            <td>{{ detailInfo.company_name}}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">开票单位</td>
            <td>{{ detailInfo.invoice.name }}</td>
            <td colspan="4" class="des" style="width:120px;">生成时间</td>
            <td>{{detailInfo.created_at}}</td>
          </tr>
          <tr>
            
            <td class="des" style="width:120px;">在职人数/人</td>
            <td>{{ detailInfo.total_employees }}</td>
            <td colspan="4" class="des" style="width:120px;">开票金额/元</td>
            <td>{{detailInfo.total_invoice}}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">应发工资/元</td>
            <td>{{ detailInfo.total_money}}</td>
            <td colspan="4" class="des" style="width:120px;">状态</td>
            <td>{{ statusOpt[detailInfo.status]}}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">是否上传</td>
            <td>{{detailInfo.settlement_file_path != '' ? '是' : '否'}}</td>
            <td colspan="4" class="des" style="width:120px;">上传时间</td>
            <td>{{detailInfo.file_updated_at}}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">确认时间</td>
            <td>{{ detailInfo.check_at }}</td>
            <td colspan="4" class="des" style="width:120px;">定稿时间</td>
            <td>{{ detailInfo.confirm_at }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">发放时间</td>
            <td>{{ detailInfo.issue_at }}</td>
            <td colspan="4" class="des" style="width:120px;">工 资 表</td>
            <td><el-button type="text" @click="handleExport">下载</el-button></td>
          </tr>
        </tbody>
      </table>
    </diV> 
  </el-dialog>
</template>
<script>
import {exportData} from '@/util'
  export default {
    name: 'PerformanceDrawDetail',
    data() {
      return {
        visible: false,
        detailInfo: {
          invoice: {},
        },
        statusOpt: {
          1:'待确认',
          2:'已确认',
          3:'待定稿',
          4:'待发放',
          5:'已发放'
        }
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        if(!!row) {        
          this.$http.post('/admin/finance/detail', { id: row.id }).then(res => {
            if(res.code === 1) {
              this.detailInfo = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 下载
      handleExport() {
        exportData({id: this.detailInfo.id},'/admin/finance/export')
      }
     
    }
  }
</script>
<style scoped lang="scss">
// 详情自定义table表格样式
.tableWrap .list {
  padding: 0 12px;
  margin-bottom: 30px;
}
 .tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
 .tableWrap table td{
  height: 30px;
  line-height: 22px;
  padding: 5px;
  border: 1px solid #ddd;
}
.tableWrap .des {
  background: #fbfbfb;
   width: 90px;
}
.tableWrap .d-title {
  height: 80px;
  line-height: 80px;
}

</style>
