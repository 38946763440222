<template>
  <el-dialog
    append-to-body
    width="600px"
    title="离职"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='130px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <el-form-item label="员工姓名：">
        <span>{{this.form.data.name}}</span>
      </el-form-item>
      <el-form-item label="性别：">
        <span>{{this.form.data.sex === 1 ? '男' : '女'}}</span>
      </el-form-item>
      <el-form-item label="用工单位：">
        <span>{{this.form.data.company.name}}</span>
      </el-form-item>
      <el-form-item label="入职日期：">
        <span>{{this.form.data.entry_at}}</span>
      </el-form-item>
      <el-form-item prop="type" label="离职原因：">
        <el-select v-model="form.data.type" placeholder="请选择离职原因" clearable filterable>
          <el-option
            v-for="item in typeOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="time" label="离职时间：">
        <el-date-picker
          v-model="form.data.time"
          type="date"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd">
      </el-date-picker>
      </el-form-item>
      
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'SystemUnitEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            company: {},
            type: '',
            time: '',
          },
          rules: {
            type: [{required: true, message: "请选择离职原因！", trigger: "change"}],
            time: [{required: true, message: "请选择离职日期！", trigger: "change"}],
          }
        },
        typeOpt: [
          {
            value: 1,
            label: '正常离职'
          },
          {
            value: 2,
            label: '自离'
          },
        ],
       
      }
    },
    methods: {
      // 获取组织详情
      getDetail(row) {
        this.isChange = true
        this.form.data = row;
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           this.$http.post('/admin/employee/dimission', this.form.data).then(res => {
             if(res.code === 1) {
               this.toggle(false)
               this.$emit('refresh');
              } else {
                this.$message.error(res.msg)
              }
           })
          }
        })
      },
    }
  }
</script>
