<template>
  <div class="main-view">
    <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
      <el-form-item label="年份筛选" prop="create_year">
        <el-date-picker
          v-model="table.params.create_year"
          type="year"
          placeholder="选择年"
          format="yyyy年"
          value-format="yyyy">
        </el-date-picker>
        <span
          :class="table.params.create_month == v ? 'monthV acColor' : 'monthV' "
          v-for="v in this.monthObj"
          :key="v"
          @click="selMonth(v)"
        >{{v}}月</span>
      </el-form-item>
    </el-form>
    <div class="tableType">
      <el-radio-group v-model="table.params.status" @change="handleTabs">
        <el-radio-button :label="1">待确认({{statusTotal.to_review_count}})</el-radio-button>
        <el-radio-button :label="2">已确认({{statusTotal.has_review_count}})</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_id">
          <el-select v-model="table.params.company_id" placeholder="请选择" filterable>
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年份筛选" prop="year">
          <el-date-picker
            v-model="table.params.year"
            type="year"
            placeholder="选择年"
            format="yyyy年"
            value-format="yyyy">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="月份筛选" prop="month">
          <el-date-picker
            v-model="table.params.month"
            type="month"
            placeholder="选择月"
            format="MM月"
            value-format="MM">
          </el-date-picker>
        </el-form-item>
        <template v-if="this.table.params.status === 2">
          <el-form-item label="发放状态" prop="pay_status">
            <el-select v-model="table.params.pay_status" placeholder="请选择" filterable>
              <el-option
                v-for="item in payStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showAdd">新增工资表</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="table.params.status === 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:year="{row}">
        <span>{{row.year}}-{{row.month}}</span>
      </template>
      <!-- status 结算状态：1-待复核；2-已复核； -->
      <!-- pay_status 发放状态 0 未定稿 1待发放 2 已回款已发放 3 未回款已垫付 -->
      <template v-slot:pay_status="{row}">
        <p v-for="item in payStatus" :key="item.id">
          <span v-if="item.id === row.pay_status">{{item.name}}</span>
        </p>
        <!-- <span>{{toStr({1:'待复核',2:'已复核',3:'待定稿', 4:'待发放', 5:'已发放'},row.status)}}</span> -->
      </template>
      <template v-slot:gzb="{row}">
         <!-- 只有分公司的待确认状态可以上传工资表，其他状态以及总部都是预览工资表(在职人数为0时禁用预览按钮) -->
        <el-button v-if="row.status === 1" type="text" @click="handleUpload(row)">上传</el-button>
        <el-button type="text" @click="handlePreview(row)" :disabled="row.total_employees === 0 ? true : false">预览</el-button>
      </template>
      <template v-slot:action="{row}">
        <template v-if="row.status === 1">
          <el-button type="text" @click="handleUntreadList(row, '5')">不发</el-button>
          <el-button type="text" @click='handleCheck(row)'>复核</el-button>
          <el-button type="text" @click="showEdit(row)">确认</el-button>
        </template>
        <!-- 状态为：已复核 并且 待发放时 增加发放操作 （只针对管理员操作） -->
        <template v-if="row.status === 2 && row.pay_status == 1 && role_type == 1">
          <el-button type="text" @click="handleConfirm(row)">发放</el-button>
        </template>
        <template v-if="row.status === 2">
          <el-button type="text" @click="showDetail(row)">查看</el-button>
        </template>
        
        <el-button type="text" @click="handleExport(row)">下载</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable" :companyList="companyOpt"></edit>
    <detail ref="detail"></detail>
    <dimission ref="dimission" @refresh="getTable"></dimission>
    <preview ref="preview"></preview>
    <untread-list ref="UntreadList"></untread-list>
    <!-- 新增福利费 -->
    <add ref="add" @refresh="getTable"></add>
    <!-- 发放 -->
    <confirm ref="confirm" @refresh="getTable"></confirm>

    <el-dialog
      title="上传"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div>
        <el-upload
          class="upload-demo"
          drag
          :limit="1"
          action=""
          :headers="{token:token}"
          :file-list="fileList"
          :on-remove="removeFile"
          :multiple="false"
          :http-request="httpRequestBack"
          accept=".xls,.xlsx">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div class="" v-if="is_withholding_performance == 1">
          <p>累计考核绩效：{{withholding_performance_total}}元</p>
          <p>累计发放绩效：{{withholding_performance_pay_total}}元</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadSubmit">确 定</el-button>
      </span>
    </el-dialog>

    
  </div>
</template>

<script>
import Edit from './components/Edit.vue'
import Detail from './components/Detail.vue'
import Dimission from './components/Dimission.vue'
import Preview from './components/Preview.vue'
import Add from './components/Add.vue'
import UntreadList from './components/untreadList.vue';
import Confirm from './components/Confirm.vue'
import VTable from '@/components/VTable';
import { exportData } from '@/util'
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'wageSettlementIndex',
  mixins:[mixinTable],
  components: {
    Edit,
    Detail,
    Dimission,
    Preview,
    VTable,
    Add,
    Confirm,
    UntreadList
  },
  computed: {
    ...mapGetters({
      companyOpt: 'companyList', // 获取企业列表
    })
  },
  data() {
    return {
      token: getStorage('token'),
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司，2:鹰潭分公司）
      role_type: getStorage('role_type'), // 1:管理员，2：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"50"},
        { name: "year", label: "工资月份", hidden: false },
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "created_at", label: "生成时间", hidden: false},
        { name: "total_employees", label: "在职总数/人", hidden: false},
        // { name: "extra_invoice", label: "福利费/元", hidden: false},
        { name: "total_invoice", label: "开票金额/元", hidden: false},
        { name: "total_money", label: "应发工资/元", hidden: false},
        { name: "total_salary", label: "实发工资/元", hidden: false},
        { name: "pay_status", label: "状态", hidden: false},
        { name: "gzb", label: "工资表", hidden: false},
        { name: "action", label: "操作", width: "180", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID"},
        { name: "year", label: "工资月份", hidden: false },
        { name: "company_name", label: "用工单位", hidden: false },
        { name: "created_at", label: "生成时间", hidden: false},
        { name: "total_employees", label: "在职总数/人", hidden: false},
        // { name: "extra_invoice", label: "福利费/元", hidden: false},
        { name: "total_invoice", label: "开票金额/元", hidden: false},
        { name: "total_money", label: "应发工资/元", hidden: false},
        { name: "total_salary", label: "实发工资/元", hidden: false},
        { name: "check_at", label: "确认时间", hidden: false},
        { name: "pay_status", label: "状态", hidden: false},
        { name: "gzb", label: "工资表", hidden: false},
        { name: "action", label: "操作", width: "160", hidden: false }
      ],
      monthObj:['01','02','03','04','05','06','07','08','09','10','11','12'],
      table: {
        loading: false,
        params: {
          company_name: '',
          company_id: '',
          status: 1,
          pay_status: '',
          year: '',
          month: '',
          create_year: '', // 生成年
          create_month: '', // 生成月
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      managerFeeType: {
        1:'企业',
        2:'员工'
      },
      settlementType: {
        1:'当月薪资',
        2:'上月薪资'
      },
      rowId: '',
      isDetail: false,
      payStatus: [
        {
          id: 0,
          name :'未定稿'
        },
        {
          id: 1,
          name :'待发放 '
        },
        {
          id: 2,
          name :'已回款已发放'
        },
        {
          id: 3,
          name :'未回款已垫付'
        }
      ],
      statusTotal: {
        to_review_count: '',
        has_review_count: '',
      },
      dialogVisible: false,
      fileList: [],
      upload: {
        file_path: '',
      },
      is_withholding_performance: 0, // 是否有填写过考核绩效
      withholding_performance_total: 0, // 累计考核绩效
      withholding_performance_pay_total: 0, // 累计发放绩效
    }
  },
  created() {
    // 默认当前年月
    this.table.params.create_year = this.$moment().format("yyyy");
    this.table.params.create_month = this.monthObj[this.$moment().format("MM") - 1]
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.post('/admin/finance/list', this.table.params).then(res => {
        if(res.code === 1) {
          this.statusTotal.to_review_count = res.data.to_review_count
          this.statusTotal.has_review_count = res.data.has_review_count
  
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    showAdd() {
      let dom = this.$refs.add
      dom.toggle(true)
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            console.log('删除接口');
            done()
          } else {
            done()
          }
        }
      })
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 不发 5-暂不发放
    handleUntreadList(row, type) {
      let dom = this.$refs.UntreadList
      dom.toggle(true)
      dom.getDetail(row, type)
      dom = null
    },
    // 确认
    showAffirm(row) {
      let dom = this.$refs.dimission
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 批量选择的数据
    handleSelectionChange(batchData) {
      console.log(batchData);
    },
    // 复核
    handleCheck(row) {
      this.$confirm("请确认操作？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/admin/finance/recheck', {id: row.id}).then(res => {
              if(res.code === 1) {
                this.$message.success('操作成功！')
                this.getTable();
              } else {
                this.$message.error(res.msg)
              }
            })
            done()
          } else {
            done()
          }
        }
      })
    },
    // 导出
    handleExport(row) {
      exportData({id: row.id},'/admin/finance/export')
    },
    // 上传
    handleUpload(row) {
      this.upload = row;
      this.fileList = [];
      this.is_withholding_performance = 0;
      this.dialogVisible = true;
    },
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      formData.append('company_id', this.upload.company_id);
      formData.append('year', this.upload.year);
      formData.append('month', this.upload.month);
      this.$http.post('/admin/finance/uploadStatementsFile',{
        formData, type:'upload',
        obj:{
          company_id:this.upload.company_id,
          year: this.upload.year,
          month: this.upload.month
        }
        }).then(res => {
          if(res.code === 1) {
            this.is_withholding_performance = res.data.is_withholding_performance;
            this.withholding_performance_total = res.data.withholding_performance_total;
            this.withholding_performance_pay_total = res.data.withholding_performance_pay_total;
            this.upload.file_path = res.data.file_path
            let str = {
              url: res.data.file_path,
              name: file.file.name
            }
            this.fileList.push(str);
          } else {
            this.$message.error(res.msg)
          }
      })
    },
    uploadSubmit() {
      let _params = {
        company_id: this.upload.company_id,
        year: this.upload.year,
        month: this.upload.month,
        file_path: this.upload.file_path,
      }
      this.$http.post('/admin/finance/uploadStatements', _params).then(res => {
        if(res.code === 1) {
          this.$message.success('上传成功')
          this.dialogVisible = false
          this.getTable(); // 刷新列表
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
    },
    removeFile() {
      this.fileList = [];
    },
    // 预览
    handlePreview(row) {
      let dom = this.$refs.preview
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    selMonth(v) {
      this.table.params.create_month = v;
      this.getTable();
    },
    // 发放
    handleConfirm(row) {
      let dom = this.$refs.confirm
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 转译
    toStr(data,key) {
      return data[key]
    }
  }
}
</script>

<style scoped>
.monthV { 
  margin-left: 16px;
  cursor: pointer;
  font-weight: bold;
  color: #595959;
}
.acColor {
  color: #409eff;
}
</style>