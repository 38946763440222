<template>
  <el-dialog
    append-to-body
    width="500px"
    title="确认"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form ref="elFormDom">
      <div class="dialog-wrap">
        <p class="company-name">{{curRow.company_name}}</p>
        <p>在职人数：{{curRow.total_employees}}人</p>
        <p>应发工资：{{curRow.total_money}}元</p>
        <p>实发工资：{{curRow.total_salary}}元</p>
      </div>
      <el-row type="flex" justify="center" style="margin-top:50px">
          <el-button type="primary" @click="confirm">确认复核</el-button>
      </el-row>
    </el-form>
  </el-dialog>
</template>

<script>
  export default {
    name: 'SettlementEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        curRow: {},
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.curRow = row;
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$confirm("请确认操作？", "提示", {
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              this.$http.post('/admin/finance/check', {id: this.curRow.id}).then(res => {
                if(res.code === 1) {
                  this.$message.success('操作成功！')
                  this.toggle(false);
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.msg)
                }
              })
              done()
            } else {
              done()
            }
          }
        })
      },
    }
  }
</script>
<style scoped lang="scss">
  .dialog-wrap {
    text-align: center;
    p {
      margin: 20 auto;
    }
    .company-name {
      margin-bottom: 20px;
    }
  }
</style>