<template>
  <el-dialog
    append-to-body
    width="500px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="dialog-wrap">
      <el-form
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <!-- 发放 -->
        <p>发放单位:{{company_name}}</p>
        <el-radio-group v-model="form.data.pay_status" prop="pay_status" size="small">
          <el-radio :label="2" border>已回款已发放</el-radio>
          <el-radio :label="3" border>未回款已垫付</el-radio>
        </el-radio-group>
      </el-form>
    </div>
    <el-row type="flex" justify="center" style="margin-top:50px">
      <!-- 发放  -->
      <el-button type="primary" @click="handleSubmit">确认</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'SystemUnitEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          data: {
            id:'',
            pay_status: 2,
          },
        },
        company_name:'', // 发放单位
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true
        this.company_name = row.company_name;
        this.form.data.id = row.id;
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 发放
      handleSubmit() {
        this.$confirm("确认操作？", "提示", {
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              this.$http.post('/admin/finance/pay', this.form.data).then(res => {
                if(res.code ===1) {
                  this.toggle(false);
                  this.$emit('refresh')
                } else {
                  this.$message.error(res.msg)
                }
              })
              done()
            } else {
              done()
            }
          }
        })
      },
    }
  }
</script>
