<template>
  <div class="addPerson__wrap" v-show="visible">
    <el-button class="backBtn" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <el-divider content-position="center">预览工资表</el-divider>
      <div class="tableWrap">
        <VTable
          has-pagionation
          title=''
          addText='添加'
          :field="field"
          :loading="table.loading"
          :data="table.data"
          :page='table.params.page'
          :pageSize='table.params.count'
          :total='table.total'
          :tree-props="{children: 'children', hasChildren: 'has_child'}"
          :hasPagionation="true"
          :default-expand-all="true"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        </VTable>
      </div>
    </div>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
export default {
  name: 'Preview',
  components: {
    VTable
  },
  data() {
    return {
      visible: false,
      loading: false,
      curRow: {},
      table: {
        loading: false,
        data: [],
        params: {
          page: 1,
          count: 10,
        },
        total: 0,
      },
      field: [],
    }
  },
  methods: {
    // 获取详情
    getDetail(row) {
      this.curRow = row;
      this.getTable();
    },
    getTable() {
      let _params = {
        id: this.curRow.id,
        page: this.table.params.page,
        count: this.table.params.count,
      }
      this.field = []
      this.$http.get('/admin/finance/preview',{params:_params}).then(res => {
        if(res.code === 1) {
          this.table.total = res.data.total;
          this.table.data = res.data.rows;
          let tableTitle = res.data.titles;

          for(let key in tableTitle) {
            console.log(key,tableTitle[key])
            let str = {
              name: tableTitle[key],
              label: key,
              hidden: false,
              width: (tableTitle[key] === 'id_card_no' || tableTitle[key] === 'bank_card_no' || tableTitle[key] === 'phone') ? 170 : 100,
            }
            this.field.push(str)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    // pageSize 改变时会触发
    handleSizeChange(pageSize) {
      this.table.params.count = pageSize;
      this.table.params.page = 1;
      this.getTable();
    },
    handleCurrentChange(page) {
      this.table.params.page = page;
      this.getTable();
    },
  }
}
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  
  .main {
    margin: 0 auto;
  }
}
</style>